import React from "react";
import { Grid, Typography, TextField, Tab } from "@mui/material";
import { TitleComponent } from "../../components/title_component";
import { ButtonComponent } from "../../components/form_components/button";
import { ManagementServices } from "../../services/management_services";
import { SuccessAlert, ErrorAlert } from "../../components/alert";
import { Loader } from "../../components/loader_component";
import { TabList, TabContext } from "@mui/lab";
import { tabStyle, tabItem } from "../../styles/common_styles";

const DynamicText = () => {
  const [alrtMsg, setAlrtMsg] = React.useState("");
  const [saveEnabled, setSaveEnabled] = React.useState(true);
  const [errorAlrtOpen, setErrorAlrtOpen] = React.useState(false);
  const [successAlrtOpen, setSuccessAlrtOpen] = React.useState(false);
  const [loaderData, setLoaderData] = React.useState(true);
  const [value, setValue] = React.useState("1");
  const [dynamicTextDetails, setDynamicTextDetails] = React.useState([]);
  const [dynamicTextValues, setDynamicTextValues] = React.useState({});
  const [changeValue, setChangeValue] = React.useState({
    id: null,
    text: "",
    type: null,
    appType: null,
  });

  const handleApiDynamicText = async () => {
    try {
      if (changeValue.text.length > 150) {
        setAlrtMsg("Text length should be less than 150 characters");
        setErrorAlrtOpen(true);
        return;
      }
      setLoaderData(true);
      const dynamicTextResponse = await ManagementServices.dynamixTextDetails(
        changeValue
      );
      setLoaderData(false);
      if (dynamicTextResponse.status === 1) {
        setAlrtMsg(dynamicTextResponse.message);
        setSuccessAlrtOpen(true);
        setSaveEnabled(false);
      } else {
        setAlrtMsg(dynamicTextResponse.message);
        setErrorAlrtOpen(true);
        setSaveEnabled(false);
      }
    } catch (error) {
      console.error("Error fetching dynamic text details:", error);
    }
  };

  const AddhandleApiDynamicText = async () => {
    const text = "Note";
    const payload = {
      text: text,
      type: "add",
      appType: value,
      category: 4,
    };
    try {
      if (text.length > 150) {
        setAlrtMsg("Text length should be less than 150 characters");
        setErrorAlrtOpen(true);
        return;
      }
      setLoaderData(true);
      const dynamicTextResponse = await ManagementServices.dynamixTextDetails(
        payload
      );
      setLoaderData(false);
      if (dynamicTextResponse.status === 1) {
        setAlrtMsg(dynamicTextResponse.message);
        setSuccessAlrtOpen(true);
        setSaveEnabled(false);
        handleApiDynamicTextDetails();
      } else {
        setAlrtMsg(dynamicTextResponse.message);
        setErrorAlrtOpen(true);
        setSaveEnabled(false);
      }
    } catch (error) {
      console.error("Error fetching dynamic text details:", error);
    }
  };

  const handleChangeTab = (event, newValue) => {
    setLoaderData(true);
    setValue(newValue);
    setTimeout(() => setLoaderData(false), 700);
  };

  const handleApiDynamicTextDetails = async () => {
    const payload = {
      type: "get",
      appType: value,
    };
    const dynamicTextResponseDetails =
      await ManagementServices.dynamixTextDetails(payload);
    if (dynamicTextResponseDetails?.status === 1) {
      setDynamicTextDetails(dynamicTextResponseDetails.data);
      setLoaderData(false);
    }
  };

  const closeSuccess = () => setSuccessAlrtOpen(false);
  const closeError = () => setErrorAlrtOpen(false);

  React.useEffect(() => {
    if (dynamicTextDetails.length > 0) {
      const initialValues = dynamicTextDetails.reduce((acc, item) => {
        acc[item.id] = item.text;
        return acc;
      }, {});
      setDynamicTextValues(initialValues);
    }
  }, [dynamicTextDetails]);

  const typeHeadings = {
    1: "Launch Screen",
    2: "Onboarding Screen",
    3: "Kitchen Details Screen",
    4: "Order Caution Screen",
    5: "Allergy Caution",
    // 6: "Consumer Onboarding Not Completed",
    // 7: "Consumer Logout",
  };

  const handleDynamicTextChange = (e, id) => {
    setDynamicTextValues({
      ...dynamicTextValues,
      [id]: e.target.value,
    });
    setChangeValue({
      id: id,
      text: e.target.value,
      type: "edit",
      appType: value,
    });
  };

  const handleDeleteText = async (id) => {
    const payload = {
      type: "delete",
      id: id,
      appType: value,
    };
    const dynamicTextResponseDeleteDetails =
      await ManagementServices.dynamixTextDetails(payload);
    if (dynamicTextResponseDeleteDetails?.status === 1) {
      setSuccessAlrtOpen(true);
      setLoaderData(false);
    }
    handleApiDynamicTextDetails();
  };

  React.useEffect(() => {
    handleApiDynamicTextDetails();
  }, [value]);

  // Group items by category
  const groupedTextDetails = dynamicTextDetails.reduce((acc, item) => {
    if (!acc[item.category]) {
      acc[item.category] = [];
    }
    acc[item.category].push(item);
    return acc;
  }, {});

  const length = Object.keys(groupedTextDetails).length;

  return (
    <>
      <Grid container sx={{ padding: 3 }}>
        <Grid
          item
          md={12}
          container
          justifyContent="space-between"
          alignItems="center"
          height="10vh"
        >
          <Grid item container>
            <TitleComponent title="Dynamic Text" />
            <Grid item md={4} justifyContent="center" ml={15}>
              <TabContext value={value}>
                <TabList
                  sx={tabStyle}
                  onChange={handleChangeTab}
                  aria-label="lab API tabs example"
                >
                  <Tab label="Consumer" value="1" sx={tabItem} />
                  <Tab label="Chef" value="2" sx={tabItem} />
                </TabList>
              </TabContext>
            </Grid>
          </Grid>
        </Grid>

        {loaderData ? (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{ height: "70vh" }}
          >
            <Loader />
          </Grid>
        ) : (
          <Grid
            container
            spacing={2}
            sx={{ height: "70vh", overflow: "scroll" }}
          >
            {
              // Loop through categories 1 to 5 and render items
              Object.entries(groupedTextDetails).map(([category, items]) => {
                const isCategory4 = category === "4";

                // Render categories 1 to 5
                if (parseInt(category) <= 5) {
                  return (
                    <React.Fragment key={category}>
                      <Grid item md={12}>
                        <Typography
                          variant="h6"
                          mt={2}
                          sx={{ fontWeight: "bold" }}
                          mb={1}
                        >
                          {typeHeadings[category]}
                        </Typography>
                      </Grid>

                      {/* Render items for the category */}
                      {items.map((item) => (
                        <Grid
                          item
                          md={12}
                          container
                          spacing={2}
                          alignItems="center"
                          key={item.id}
                        >
                          <Grid item md={9}>
                            <TextField
                              fullWidth
                              placeholder="Enter Your Text....."
                              value={dynamicTextValues[item.id] || ""}
                              onChange={(e) =>
                                handleDynamicTextChange(e, item.id)
                              }
                            />
                          </Grid>
                          <Grid item>
                            <ButtonComponent
                              btnText="Save"
                              onClick={handleApiDynamicText}
                            />
                          </Grid>
                        </Grid>
                      ))}

                      {/* Add "Note for Order Caution" after Category 4 */}
                      {isCategory4 && (
                        <>
                          <Grid item md={12}>
                            <Typography
                              variant="h6"
                              mt={2}
                              sx={{ fontWeight: "bold" }}
                              mb={1}
                            >
                              Note for Order Caution
                            </Typography>
                          </Grid>

                          {/* Render exactly 3 text fields for categories 6, 7, and 8 */}
                          {["6", "7", "8"].map((catKey) =>
                            (groupedTextDetails[catKey] || [])
                              .slice(0, 1)
                              .map((item) => (
                                <Grid
                                  item
                                  md={12}
                                  container
                                  spacing={2}
                                  alignItems="center"
                                  key={item.id}
                                >
                                  <Grid item md={9}>
                                    <TextField
                                      fullWidth
                                      placeholder="Enter Your Text....."
                                      value={dynamicTextValues[item.id] || ""}
                                      onChange={(e) =>
                                        handleDynamicTextChange(e, item.id)
                                      }
                                    />
                                  </Grid>
                                  <Grid item>
                                    <ButtonComponent
                                      btnText="Save"
                                      onClick={handleApiDynamicText}
                                    />
                                  </Grid>
                                </Grid>
                              ))
                          )}
                        </>
                      )}
                    </React.Fragment>
                  );
                }

                return null;
              })
            }
          </Grid>
        )}

        <SuccessAlert
          alertMsg={alrtMsg}
          open={successAlrtOpen}
          close={closeSuccess}
        />
        <ErrorAlert
          alertMsg={alrtMsg}
          open={errorAlrtOpen}
          close={closeError}
        />
      </Grid>
    </>
  );
};

export default DynamicText;
