import * as React from "react";
import {
  Button,
  Grid,
  Typography,
  TextField,
  FormControl,
} from "@mui/material";
// import logo from "../../assets/images/logo.png";
import YoufoodUserLogo from "../../assets/images/Logo.png";
import india from "../../assets/images/india.png";
import usa from "../../assets/images/usa.png";
import uk from "../../assets/images/uk.png";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import { ManagementServices } from "../../services/management_services";
import {
  container1,
  container1_1,
  container2_1,
  container5,
  container6,
  container1_1_text,
  container1_1_text1,
  container5_1_text1,
  container5_1_text2,
  contactUsButton,
  container5_2,
  countryHolder,
  contactHolder,
  contactModal,
  modalHeading,
  cardContainer,
} from "../../styles/common_styles";
import { SuccessAlert } from "../../components/alert";
import { ErrorAlert } from "../../components/alert";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useForm } from "react-hook-form";
import pdfimage1 from "../../assets/images/pdfimage1.png";
import pdfimage2 from "../../assets/images/pdfimage2.png";
import pdfimage3 from "../../assets/images/pdfimage3.png";
import pdfimage4 from "../../assets/images/pdfimage4.png";

export const HomepagePdf = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [name, setName] = React.useState();
  const [email, setEmail] = React.useState("");
  const [message, setMessage] = React.useState();
  const [alrtMsg, setAlrtMsg] = React.useState("");
  const [successAlrtOpen, setSuccessAlrtOpen] = React.useState(false);
  const [errorAlrtOpen, setErrorAlrtOpen] = React.useState(false);
  const [passwordModal, setPasswordModal] = React.useState(true);
  const [showPassword, setShowPassword] = React.useState(false);
  const [password, setPassword] = React.useState("");
  const [error, setError] = React.useState(false);
  const [emailError, setEmailError] = React.useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleFieldChange = (e, feildName) => {
    if (feildName === "Name") {
      setName(e.target.value);
    } else if (feildName === "Email") {
      setEmail(e.target.value);
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const isValidEmail = emailPattern.test(e.target.value);
      // Update email error state based on email validation
      setEmailError(!isValidEmail);
    } else {
      setMessage(e.target.value);
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const closeSuccess = () => {
    setSuccessAlrtOpen(false);
  };

  const closeError = () => {
    setErrorAlrtOpen(false);
  };

  const onSubmit = async (data) => {
    try {
      const { name, email, description } = data;
      // Create payload object
      const payload = {
        name: name,
        email: email,
        message: description, // Assuming description corresponds to the message field
      };
      // Call your API endpoint
      const contactUsDetails = await ManagementServices.InsertContactUsInfo(
        payload
      );

      // Handle response
      if (contactUsDetails.status === 1) {
        setAlrtMsg("Thank you for contacting YouFood");
        setSuccessAlrtOpen(true);
        handleClose();
        reset();
      } else {
        setAlrtMsg(contactUsDetails.message);
        setErrorAlrtOpen(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlePasswordSubmit = async (event) => {
    event.preventDefault();
    if (password === "Krify@123") {
      setPasswordModal(false);
    } else {
      setError(true);
    }
  };

  const openPdf = () => {
    window.open(
      "https://homefoodbucket.s3.us-east-1.amazonaws.com/staging/userOnboardingPdf/a-onboarding1732948690052.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAXYKJRSN2LIWOAGYW%2F20241130%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20241130T084900Z&X-Amz-Expires=900&X-Amz-Signature=b2695aa46beacfe6de9c2961cf9734497564ed5b2d56e0cdc21bfb293ec540b6&X-Amz-SignedHeaders=host&response-content-disposition=inline&x-id=GetObject"
    );
  };

  return (
    <>
      {!passwordModal && (
        <Grid container>
          <Grid item md={12} sx={container1}>
            <Grid sx={container1_1}>
              <img
                src={YoufoodUserLogo}
                alt="logo"
                style={{ width: "150px", height: "auto", borderRadius: "15px" }}
              />
              <Grid pt={5}>
                <Typography sx={container1_1_text}>SOLUTION LIBRARY</Typography>
                <Typography
                  md={6}
                  pt={4}
                  sx={{
                    fontWeight: 700,
                    fontSize: "22px",
                    color: "#A7A7A7",
                    width: "60%",
                  }}
                >
                  Explore our collection of helpful PDFs to find answers to
                  common questions. Each document offers clear solutions and
                  guidance. Select a PDF below to get started on resolving your
                  query quickly.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={12} sx={{ margin: "50px" }}>
            <Grid
              item
              md={12}
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Grid item md={6}>
                <img
                  src={pdfimage1}
                  alt="logo"
                  style={{ width: "100%", height: "auto", cursor: "pointer" }}
                  onClick={openPdf}
                />
              </Grid>
              <Grid item md={6}>
                <img
                  src={pdfimage2}
                  alt="logo"
                  style={{ width: "100%", height: "auto", cursor: "pointer" }}
                  onClick={openPdf}
                />
              </Grid>
            </Grid>
            <Grid
              item
              md={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Grid item md={6}>
                <img
                  src={pdfimage3}
                  alt="logo"
                  style={{ width: "100%", height: "auto", cursor: "pointer" }}
                  onClick={openPdf}
                />
              </Grid>
              <Grid item md={6}>
                <img
                  src={pdfimage4}
                  alt="logo"
                  style={{ width: "100%", height: "auto", cursor: "pointer" }}
                  onClick={openPdf}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={12} pb={10}>
            <Grid container>
              <Grid item md={6} sx={container5}>
                <Typography sx={container5_1_text1}>
                  For inquiries about our food application, our admin is here to
                  help.
                </Typography>
                <Typography sx={container5_1_text2}>
                  GET IN TOUCH NOW!
                </Typography>
                <Button sx={contactUsButton} onClick={handleOpen}>
                  CONTACT US
                </Button>
              </Grid>
              <Grid item md={6} sx={container5_2}>
                <Grid p={3} m={2} sx={cardContainer}>
                  <Grid container>
                    <Grid width={"100px"}>
                      <Typography sx={countryHolder}>INDIA :</Typography>
                    </Grid>
                    <Typography sx={contactHolder}>+91 1234567890</Typography>
                  </Grid>
                  <Grid container>
                    <Grid width={"100px"} p={1}>
                      <img height={44} width={44} src={india} alt="india" />
                    </Grid>
                    <Grid>
                      <Typography sx={contactHolder}>
                        indianyoufood@gmail.com
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid p={3} m={2} sx={cardContainer}>
                  <Grid container>
                    <Grid width={"100px"}>
                      <Typography sx={countryHolder}>USA :</Typography>
                    </Grid>
                    <Typography sx={contactHolder}>+41 1234567890</Typography>
                  </Grid>
                  <Grid container>
                    <Grid width={"100px"} p={1}>
                      <img height={44} width={44} src={usa} alt="usa" />
                    </Grid>
                    <Grid>
                      <Typography sx={contactHolder}>
                        usayoufood@gmail.com
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid p={3} m={2} sx={cardContainer}>
                  <Grid container>
                    <Grid width={"100px"}>
                      <Typography sx={countryHolder}>UK :</Typography>
                    </Grid>
                    <Typography sx={contactHolder}>+91 1234567890</Typography>
                  </Grid>
                  <Grid container>
                    <Grid width={"100px"}>
                      <img height={44} width={44} src={uk} alt="uk" />
                    </Grid>
                    <Grid>
                      <Typography sx={contactHolder}>
                        ukyoufood@gmail.com
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={12} sx={container6}>
            Copyright © 2024 YouFood Delivery
          </Grid>
        </Grid>
      )}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={contactModal}>
          <Grid item>
            <Typography sx={modalHeading}>CONTACT US</Typography>
          </Grid>
          <Grid container justifyContent={"center"} spacing={3}>
            <Grid item md={10}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      {...register("name", { required: "Name is required" })}
                      fullWidth
                      label="Name"
                      size="small"
                      error={!!errors.name}
                      helperText={errors.name?.message}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      {...register("email", {
                        required: "Email is required",
                        pattern: {
                          value: /^\S+@\S+$/i,
                          message: "Invalid email address",
                        },
                      })}
                      fullWidth
                      label="Email"
                      size="small"
                      error={!!errors.email}
                      helperText={errors.email?.message}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextareaAutosize
                      {...register("description", {
                        required: "Description is required",
                      })}
                      rowsMin={5}
                      placeholder="Enter Your Description"
                      style={{ width: "99%", height: 200, borderRadius: 10 }}
                      error={!!errors.description}
                    />
                    {errors.description && (
                      <Typography color="error" variant="caption">
                        {errors.description.message}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} sx={{ textAlign: "center" }}>
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{ backgroundColor: "black" }}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <SuccessAlert
        alertMsg={alrtMsg}
        open={successAlrtOpen}
        close={closeSuccess}
      />
      <ErrorAlert alertMsg={alrtMsg} open={errorAlrtOpen} close={closeError} />
      <Modal
        open={passwordModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Authentication
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <form onSubmit={(event) => handlePasswordSubmit(event)}>
              <FormControl sx={{ m: 1, width: "100%" }} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">
                  Password
                </InputLabel>
                <OutlinedInput
                  width="100%"
                  id="outlined-adornment-password"
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => {
                    setError(false);
                    setPassword(e.target.value);
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
                {error && (
                  <p style={{ color: "red" }}>Please enter valid password</p>
                )}
              </FormControl>
              <Grid
                sx={{ width: "100%", textAlign: "center", padding: "10px" }}
              >
                <Button variant="contained" type="submit" size="medium">
                  submit
                </Button>
              </Grid>
            </form>
          </Typography>
        </Box>
      </Modal>
    </>
  );
};
