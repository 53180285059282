import * as React from "react";
import { Grid, Tab, Select, FormControl, MenuItem } from "@mui/material";
import { TabList, TabPanel, TabContext } from "@mui/lab";
import { TitleComponent } from "../../components/title_component";
import {
  tabStyle,
  tabItem,
  moduleHeadContainer,
} from "../../styles/common_styles";
import "../../styles/common_styles.css";
import { CommonServices } from "../../services/common_services";
import { BackDropLoader } from "../../components/loader_component";
import { IncompleteOnBoardingHandling } from "../../components/incomplete_onboarding_handling";
import { SearchFieldComponent } from "../../components/form_components/search_field";

const IncompleteOnBoarding = () => {
  const [value, setValue] = React.useState("1");
  const [selectedCountry, setSelectedCountry] = React.useState(0);
  const [countries, setCountries] = React.useState([]);
  const [states, setStates] = React.useState([]);
  const [selectedState, setSelectedState] = React.useState(0);
  const [backDropLoaderOpen, setBackDropLoaderOpen] = React.useState(false);
  const [searchData, setSearchData] = React.useState("");
  const [incompleteCount, setIncompleteCount] = React.useState(0);

  const handleToggleBackDropLoader = () => {
    setBackDropLoaderOpen(!backDropLoaderOpen);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeCountry = (event) => {
    const { value } = event.target;
    setSelectedCountry(value);
    GetStates(2, event.target.value);
    setSelectedState(0);
  };

  const handleChangeState = (event) => {
    const { value } = event.target;
    setSelectedState(value);
  };

  const GetCountries = async () => {
    try {
      const payload = {
        type: 1,
      };
      const Countries = await CommonServices.getLocation(payload);

      if (Countries.status === 1) {
        setCountries(Countries.data);
      } else {
        alert(Countries.message);
      }
    } catch (error) {
      console.error(`Error occured while fetching roles:${error}`);
    }
  };

  const GetStates = async (type, country) => {
    try {
      const payload = {
        type: type,
        country: country,
      };
      const States = await CommonServices.getLocation(payload);

      if (States.status === 1) {
        setStates(States.data);
      } else {
        alert(States.message);
      }
    } catch (error) {
      console.error(`Error occured while fetching roles:${error}`);
    }
  };

  const handleSearchData = async (data) => {
    setSearchData(data);
  };

  const totalCountries = countries.map((x) => (
    <MenuItem key={x.id} value={x.id}>
      {x.name}
    </MenuItem>
  ));

  const totalStates = states?.map((x) => (
    <MenuItem key={x.id} value={x.id}>
      {x.name}
    </MenuItem>
  ));

  React.useEffect(() => {
    GetCountries();
  }, []);

  return (
    <>
      <BackDropLoader
        backDropLoaderopen={backDropLoaderOpen}
        BackDropLoaderHandleClose={handleToggleBackDropLoader}
      />
      <Grid
        container
        justifyContent={"space-around"}
        alignItems="center"
        // spacing={2}
        sx={moduleHeadContainer}
      >
        <Grid item md={4}>
          <TitleComponent
            title={`Incomplete Onboarding - ${incompleteCount}`}
          />
        </Grid>
        <Grid item md={3}>
          <TabContext value={value}>
            <TabList
              sx={tabStyle}
              onChange={handleChange}
              aria-label="lab API tabs example"
            >
              <Tab label="Consumer" value="1" sx={tabItem} />
              <Tab label="Chef" value="2" sx={tabItem} />
            </TabList>
          </TabContext>
        </Grid>
        <Grid item md={4}>
          <Grid container justifyContent={"end"} spacing={2}>
            <Grid item md={4}>
              <SearchFieldComponent
                searchText={handleSearchData}
                className={"searchMargin"}
              />
            </Grid>
            <Grid item md={4}>
              <FormControl fullWidth size="small">
                <Select
                  value={selectedCountry}
                  onChange={handleChangeCountry}
                  displayEmpty
                  inputProps={{ "aria-label": "Country" }}
                >
                  <MenuItem value={0}>Select Country</MenuItem>
                  {totalCountries}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <FormControl fullWidth size="small">
                <Select
                  value={selectedState}
                  onChange={handleChangeState}
                  displayEmpty
                  inputProps={{ "aria-label": "State" }}
                >
                  <MenuItem value={0}>Select state</MenuItem>
                  {totalStates}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* TabPanel component moved after the parent Grid */}
      <TabContext value={value}>
        <TabPanel value={value} className="tabTable">
          <IncompleteOnBoardingHandling
            formModule={value}
            country={selectedCountry}
            state={selectedState}
            search_data={searchData}
            setIncompleteCount={setIncompleteCount}
          />
        </TabPanel>
      </TabContext>
    </>
  );
};

export default IncompleteOnBoarding;
