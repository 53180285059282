import * as React from "react";
import { TableComponent } from "./table_component";
import { TableCell, TableRow } from "@mui/material";
import { tableHeadCell } from "../styles/common_styles";
import { Loader } from "./loader_component";
import { HFPagination } from "../components/pagination";
import { ManagementServices } from "../services/management_services";
import { SuccessAlert, ErrorAlert } from "../components/alert";
import { BlockOrDeleteModalComponent } from "../components/modal_component";
import { MessageModalComponent } from "./message_modal";
import ViewIcon from "../assets/icons/view.svg";
import { useNavigate } from "react-router-dom";
import MessageIcon from "../assets/icons/message.svg";
import { GetRoleDetails } from "../utils/helpers";
import {
  TableCellComponent,
  IconTableCellComponent,
} from "./table_cell_component";

export const IncompleteOnBoardingHandling = ({
  formModule,
  country,
  state,
  search_data,
  setIncompleteCount,
}) => {
  const navigate = useNavigate();
  const [loaderData, setLoaderData] = React.useState(true);
  const [alrtMsg, setAlrtMsg] = React.useState("");
  const [successAlrtOpen, setSuccessAlrtOpen] = React.useState(false);
  const [errorAlrtOpen, setErrorAlrtOpen] = React.useState(false);
  const [incompleteOnboarding, setIncompleteOnboarding] = React.useState([]);
  const [actionId, setActionId] = React.useState("");
  const [RoleDetails, setRoleDetails] = React.useState();
  const [messageModalOpen, setMessageModalOpen] = React.useState(false);
  const [iconData, setIconData] = React.useState("");

  const closeSuccess = () => {
    setSuccessAlrtOpen(false);
  };

  const closeError = () => {
    setErrorAlrtOpen(false);
  };
  const handleMessageModalOpen = (data) => {
    setMessageModalOpen(true);
    setIconData(data);
  };

  const [pageConfig, setPageConfig] = React.useState({
    min: 1,
    max: 10,
  });

  const [actionType, setActiontype] = React.useState({
    title: "",
    type: null,
  });
  const [paginationData, setPaginationData] = React.useState({
    currentPage: null,
    pageCount: null,
    totalRecords: null,
    RecordsForPage: null,
  });
  const [deleteModal, setDeleteModal] = React.useState(false);
  const DeleteModalClose = () => setDeleteModal(false);

  const blockOrDeleteHandleOpen = (id, title, type) => {
    setActionId(id);
    setDeleteModal(true);
    setActiontype({ ...actionType, title, type });
  };

  const handleDeleteFunction = async (data) => {
    try {
      //handles No condition
      if (data === 1) {
        DeleteModalClose();
      } else {
        if (formModule === "1") {
          const deletedpendingconsumer =
            await ManagementServices.deletePendingOnboardingConsumer(actionId);
          if (deletedpendingconsumer.status) {
            setAlrtMsg(deletedpendingconsumer.message);
            setSuccessAlrtOpen(true);
            ConsumerincompleteOnBoardingadding();
            DeleteModalClose();
          } else {
            setAlrtMsg(deletedpendingconsumer.message);
            setErrorAlrtOpen(true);
          }
        } else {
          const deletedpendingchef =
            await ManagementServices.deletePendingOnboardingChef(actionId);
          if (deletedpendingchef.status) {
            setAlrtMsg(deletedpendingchef.message);
            setSuccessAlrtOpen(true);
            ConsumerincompleteOnBoardingadding();
            DeleteModalClose();
          } else {
            setAlrtMsg(deletedpendingchef.message);
            setErrorAlrtOpen(true);
          }
        }
      }
    } catch (error) {
      console.log(`Error occured :${error}`);
    }
  };

  const ConsumerincompleteOnBoardingadding = async () => {
    const payload = {
      min: pageConfig.min,
      max: pageConfig.max,
      country: country,
      search: search_data,
    };

    if (state !== 0) {
      payload.state = state;
    }

    if (formModule === "1") {
      const response = await ManagementServices.getIncompleteonboardingDetails(
        payload
      );
      const {
        data: { currentPage, pageSize, totalPages, totalUsers, consumers },
      } = response;
      if (response.status === 1) {
        setPaginationData({
          ...paginationData,
          currentPage: currentPage,
          RecordsForPage: pageSize,
          totalRecords: totalUsers,
          pageCount: totalPages,
        });
        setIncompleteCount(totalUsers);
        setIncompleteOnboarding(consumers);
        setLoaderData(false);
      } else {
        setAlrtMsg(response.message);
        setErrorAlrtOpen(true);
      }
      return;
    }

    const response =
      await ManagementServices.getIncompleteonboardingDetailsChef(payload);
    const {
      data: { currentPage, pageSize, totalPages, totalUsers, chefs },
      message,
    } = response;

    if (response.status === 1) {
      setPaginationData({
        ...paginationData,
        currentPage: currentPage,
        RecordsForPage: pageSize,
        totalRecords: totalUsers,
        pageCount: totalPages,
      });
      setIncompleteOnboarding(chefs);
      setLoaderData(false);
    } else {
      setAlrtMsg(message);
      setErrorAlrtOpen(true);
    }
    return;
  };

  const TableHeader = [
    "S No",
    "User Id",
    "User Name",
    "First Name",
    "Last Name",
    "Phone",
    "Email Id",
    "Completed",
    "MailCount",
    "Actions",
  ];

  const handlNavigate = (formModule, data) => {
    navigate(formModule === "1" ? "/Consumer/about" : "/chef/about", {
      state: { module: formModule, userId: data.id, type: 11 },
    });
  };

  const iconConfigurations = (data) => [
    {
      condition: true,
      icon: MessageIcon,
      alt: "Message",
      onClick: handleMessageModalOpen,
      // onClick: async() => {
      //   try{
      //     if (formModule === "1"){
      //       const payload = {
      //         userId:data.id
      //       }
      //       const ConsumerMessage = await ManagementServices.IncompleteOnboardingMessageToConsumer(payload)
      //       if(ConsumerMessage.status === 1){
      //         setAlrtMsg(ConsumerMessage.message);
      //         setSuccessAlrtOpen(true)
      //       }else{
      //         setAlrtMsg(ConsumerMessage.message);
      //         setErrorAlrtOpen(true);
      //       }
      //     }else{
      //       const payload = {
      //         userId:data.id
      //       }
      //       const chefMessage = await ManagementServices.IncompleteOnboardingMessageToChef(payload)
      //       if(chefMessage.status===1){
      //         setAlrtMsg(chefMessage.message);
      //         setSuccessAlrtOpen(true)
      //       }else{
      //         setAlrtMsg(chefMessage.message);
      //         setErrorAlrtOpen(true);
      //       }
      //     }
      //   }catch (error) {
      //     console.log(`Error occured :${error}`);
      //   }
      // },
    },
    {
      condition:
        RoleDetails?.role_id !== 4 &&
        RoleDetails?.role_id !== 5 &&
        RoleDetails?.role_id !== 6,
      icon: ViewIcon,
      alt: "View",
      onClick: () => {
        handlNavigate(formModule, data);
      },
    },
  ];

  const TableHeaderRow = TableHeader?.map((row, index) => (
    <TableCell key={index} sx={tableHeadCell}>
      {row}
    </TableCell>
  ));

  const TableBody = incompleteOnboarding?.map((data, index) => {
    return (
      <>
        <TableRow key={data.id}>
          <TableCellComponent
            value={
              (paginationData.currentPage - 1) * paginationData.RecordsForPage +
              1 +
              index
            }
            indexColoumn={true}
          />
          <TableCellComponent value={data.user_id} />
          <TableCellComponent value={data.user_name} />
          <TableCellComponent value={data.first_name} />
          <TableCellComponent value={data.last_name} />
          <TableCellComponent value={data.mobile} />
          <TableCellComponent value={data.email} />
          <TableCellComponent
            value={`${data.completedOnboardingCount}/${data.totalOnboardingCount}`}
          />
          <TableCellComponent
            value={
              formModule === "1"
                ? data.automatedMailCount
                : data.automateMailCount
            }
          />
          <IconTableCellComponent
            data={data}
            iconConfigurations={iconConfigurations}
          />
        </TableRow>
      </>
    );
  });

  const handlePaginate = async (page) => {
    try {
      setPageConfig({ ...pageConfig, min: page });
    } catch (error) {
      console.error(`Error occured:${error}`);
    }
  };
  const handleRoleDetails = async () => {
    const GetRoleDetailss = await GetRoleDetails();
    setRoleDetails(GetRoleDetailss);
  };

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        await ConsumerincompleteOnBoardingadding();
        await handleRoleDetails();
      } catch (error) {
        console.error(`Error in useEffect: ${error}`);
      }
    };
    fetchData();
  }, []);

  React.useEffect(() => {
    ConsumerincompleteOnBoardingadding();
  }, [pageConfig.min, country, state, search_data, formModule]);

  return (
    <>
      <MessageModalComponent
        open={messageModalOpen}
        formModule={formModule}
        iconValue={iconData}
        handleClose={() => setMessageModalOpen(false)}
      />
      {loaderData ? (
        <Loader />
      ) : (
        <>
          <TableComponent headerData={TableHeaderRow} bodyData={TableBody} />
          <HFPagination details={paginationData} paginate={handlePaginate} />
          <BlockOrDeleteModalComponent
            deleteopen={deleteModal}
            resType={handleDeleteFunction}
            onClose={DeleteModalClose}
            title={`Do you want to ${actionType.title}?`}
          />
          <SuccessAlert
            alertMsg={alrtMsg}
            open={successAlrtOpen}
            close={closeSuccess}
          />
          <ErrorAlert
            alertMsg={alrtMsg}
            open={errorAlrtOpen}
            close={closeError}
          />
        </>
      )}
    </>
  );
};
