import React from "react";
import {
  Grid,
  MenuItem,
  FormControl,
  Select,
  IconButton,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import {
  moduleHeadContainer,
  tableParentHolder,
} from "../../styles/common_styles";
import { TitleComponent } from "../../components/title_component";
import { SearchFieldComponent } from "../../components/form_components/search_field";
import { CommonServices } from "../../services/common_services";
import VisibilityIcon from "@mui/icons-material/Visibility";
import OrderModalComponent from "../../components/order_modal_component";
import { TableComponent } from "../../components/table_component";
import { EmaillableTwo } from "../../styles/loginstyles";
import { tableHeadCell } from "../../styles/common_styles";
import { HFPagination } from "../../components/pagination";
import { TableCellComponent } from "../../components/table_cell_component";
import { ManagementServices } from "../../services/management_services";
import { SuccessAlert } from "../../components/alert";
import { ErrorAlert } from "../../components/alert";
import { Loader } from "../../components/loader_component";
import "../../styles/common_styles.css";
const IncompleteOrders = () => {
  const [countries, setCountries] = React.useState([]);
  const [states, setStates] = React.useState([]);
  const [cities, setCities] = React.useState([]);
  const [selectedCountry, setSelectedCountry] = React.useState(0);
  const [selectedState, setSelectedState] = React.useState(0);
  const [selectedCity, setSelectedCity] = React.useState(0);
  const [orderStatus, setOrderStatus] = React.useState(null);
  const [searchData, setSearchData] = React.useState("");
  const [incompleteorderData, setIncompleteOrderData] = React.useState([]);
  const [orderId, setOrderId] = React.useState("");
  const [startDate, setStartDate] = React.useState();
  const [endDate, setEndDate] = React.useState();
  const [loaderData, setLoaderData] = React.useState(true);
  const [alrtMsg, setAlrtMsg] = React.useState("");
  const [successAlrtOpen, setSuccessAlrtOpen] = React.useState(false);
  const [errorAlrtOpen, setErrorAlrtOpen] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [count, SetincompleteorderCount] = React.useState(0);
  const [pageConfig, setPageConfig] = React.useState({
    min: 1,
    max: 10,
  });

  const [paginationData, setPaginationData] = React.useState({
    currentPage: null,
    pageCount: null,
    totalRecords: null,
    RecordsForPage: null,
  });

  const handlePaginate = async (page) => {
    try {
      setPageConfig({ ...pageConfig, min: page });
    } catch (error) {
      console.error(`Error occured:${error}`);
    }
  };

  const closeSuccess = () => {
    setSuccessAlrtOpen(false);
  };

  const closeError = () => {
    setErrorAlrtOpen(false);
  };
  const handleSearchData = async (data) => {
    setSearchData(data);
    setPageConfig({ ...pageConfig, min: 1, max: 10 });
  };

  const handleOpenModal = (id) => {
    setIsModalOpen(true);
    setOrderId(id);
  };
  const handleCloseModal = () => setIsModalOpen(false);

  const GetCountries = async () => {
    try {
      const payload = {
        type: 1,
      };
      const Countries = await CommonServices.getLocation(payload);

      if (Countries.status === 1) {
        setCountries(Countries.data);
      } else {
        alert(Countries.message);
      }
    } catch (error) {
      console.error(`Error occured while fetching roles:${error}`);
    }
  };

  const GetStates = async (type, country) => {
    try {
      const payload = {
        type: type,
        country: country,
      };
      const States = await CommonServices.getLocation(payload);

      if (States.status === 1) {
        setStates(States.data);
      } else {
        alert(States.message);
      }
    } catch (error) {
      console.error(`Error occured while fetching roles:${error}`);
    }
  };
  const GetCities = async (type, state, country) => {
    try {
      const payload = {
        type: type,
        country: country,
        state: state,
      };
      const Cities = await CommonServices.getLocation(payload);
      if (Cities.status === 1) {
        setCities(Cities.data);
      } else {
        alert(Cities.message);
      }
    } catch (error) {
      console.error(`Error occured while fetching roles:${error}`);
    }
  };

  const totalCountries = countries.map((x) => (
    <MenuItem key={x.id} value={x.id}>
      {x.name}
    </MenuItem>
  ));

  const totalStates = states.map((x) => (
    <MenuItem key={x.id} value={x.id}>
      {x.name}
    </MenuItem>
  ));

  const totalCities = cities.map((x) => (
    <MenuItem key={x.id} value={x.id}>
      {x.name}
    </MenuItem>
  ));

  const handleChangeCountry = (event) => {
    const { value } = event.target;
    setSelectedCountry(value);
    GetStates(2, value);
    setSelectedState(0);
    setSelectedCity(0);
  };

  const handleChangeState = (event) => {
    const { value } = event.target;
    setSelectedState(value);
    setSelectedCity(0);
    GetCities(3, event.target.value, selectedCountry);
  };

  const handleChangeCity = (event) => {
    const { value } = event.target;
    setSelectedCity(value);
  };

  const getTotalIncompleteOrders = async () => {
    try {
      const payload = {
        min: pageConfig.min,
        max: pageConfig.max,
        status: orderStatus,
        search: searchData,
        country: selectedCountry,
        state: selectedState,
        city: selectedCity,
        fromdate: startDate,
        todate: endDate,
      };
      const incompleteOrders =
        await ManagementServices.getTotalIncompleteOrders(payload);
      if (incompleteOrders.status === 1) {
        setIncompleteOrderData(incompleteOrders.data.orderDetails);
        setPaginationData({
          ...paginationData,
          currentPage: incompleteOrders.data.currentPage,
          RecordsForPage: incompleteOrders.data.pageSize,
          totalRecords: incompleteOrders.data.totalOrders,
          pageCount: incompleteOrders.data.totalPages,
        });
        SetincompleteorderCount(incompleteOrders.data.totalOrders);
        setLoaderData(false);
        // setAlrtMsg(incompleteOrders.message);
        // setSuccessAlrtOpen(true);
      } else {
        setErrorAlrtOpen(incompleteOrders.message);
      }
    } catch (error) {
      console.error(`Error occured while fetching roles:${error}`);
    }
  };

  const getStatusName = (statusNumber) => {
    const colors = [
      "#FF5733",
      "#3357FF",
      "#3357FF",
      "#FF33A8",
      "#A833FF",
      "#33A8FF",
      "#FF3333",
      "#FF5733",
      "#A833FF",
    ];

    const statusMap = {
      0: { name: "Pending", color: colors[0] },
      1: { name: "Accepted", color: colors[1] },
      2: { name: "Rejected", color: colors[2] },
      3: { name: "Started", color: colors[3] },
      4: { name: "Prepared", color: colors[4] },
      5: { name: "Ready to deliver/pickup", color: colors[5] },
      6: { name: "Delivered", color: colors[6] },
      7: { name: "Cancelled", color: colors[7] },
      8: { name: "Missed(AutoCancel)", color: colors[8] },
    };

    return statusMap[statusNumber] || { name: "Unknown", color: "#CCCCCC" };
  };

  let tableHeaders = [
    "S no",
    "Order Id",
    "Chef Name",
    "ConsumerName",
    "Order Date",
    "Order Status",
    "Order Type",
    "Cancelled By",
    "Total Spent",
    "Actions",
  ];

  const TableHeaderRow = tableHeaders.map((row, index) => (
    <TableCell key={index} sx={tableHeadCell}>
      {row}
    </TableCell>
  ));

  const formatDate = (createdAt) => {
    const date = new Date(createdAt);
    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const year = date.getUTCFullYear();
    return `${day}/${month}/${year}`;
  };

  const TableBody = incompleteorderData?.map((data, index) => {
    return (
      <TableRow key={data.id}>
        <TableCellComponent
          value={
            (paginationData.currentPage - 1) * paginationData.RecordsForPage +
            1 +
            index
          }
          indexColoumn={true}
        />
        <TableCellComponent value={data.order_id || "-"} />
        <TableCellComponent value={data.chef_name} />
        <TableCellComponent value={data.consumer_name} />
        <TableCellComponent value={formatDate(data.created_at)} />
        <TableCell
          sx={{
            color: getStatusName(data.order_status).color + " !important",
            textAlign: "center",
          }}
        >
          {getStatusName(data.order_status).name}
        </TableCell>
        <TableCellComponent
          value={data.order_type === 1 ? "Instant Order" : "Food Request"}
        />
        <TableCellComponent
          value={data.cancelled_by === 1 ? "Consumer" : "Chef"}
        />
        <TableCellComponent value={`${data.currency}${data.total}`} />
        <TableCell>
          <IconButton onClick={() => handleOpenModal(data.id)}>
            <VisibilityIcon sx={{ color: "black" }} />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  });

  const handlestatusChange = (event) => {
    setOrderStatus(event.target.value);
    setPageConfig({ ...pageConfig, min: 1, max: 10 });
  };

  React.useEffect(() => {
    GetCountries();
    getTotalIncompleteOrders();
  }, [
    selectedCountry,
    selectedState,
    selectedCity,
    pageConfig.min,
    searchData,
    orderStatus,
  ]);

  React.useEffect(() => {
    getTotalIncompleteOrders();
  }, [orderStatus, startDate, endDate]);

  return (
    <>
      <Grid container alignItems={"center"} sx={moduleHeadContainer}>
        <Grid item md={3.2}>
          <TitleComponent title={`Incomplete Orders - ${count}`} />
        </Grid>
        <Grid item md={8.8}>
          <Grid container alignItems={"center"} justifyContent={"end"} gap={1}>
            <Grid item md={1.9}>
              <TextField
                type="date"
                size="small"
                InputProps={{
                  placeholder: "startDate",
                }}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </Grid>
            <Grid item md={1.9}>
              <TextField
                type="date"
                size="small"
                InputProps={{
                  placeholder: "startDate",
                }}
                onChange={(e) => {
                  setEndDate(e.target.value);
                  getTotalIncompleteOrders();
                }}
              ></TextField>
            </Grid>
            <Grid item md={2}>
              <SearchFieldComponent searchText={handleSearchData} />
            </Grid>
            <Grid md={1.3}>
              <FormControl fullWidth>
                <Select
                  value={selectedCountry !== null ? selectedCountry : ""}
                  size={"small"}
                  displayEmpty
                  inputProps={{ "aria-label": "Country" }}
                  onChange={handleChangeCountry}
                >
                  <MenuItem value={0}>Select country</MenuItem>
                  {totalCountries}
                </Select>
              </FormControl>
            </Grid>
            <Grid md={1.3}>
              <FormControl fullWidth>
                <Select
                  value={selectedState !== null ? selectedState : ""}
                  size={"small"}
                  displayEmpty
                  inputProps={{ "aria-label": "State" }}
                  onChange={handleChangeState}
                >
                  <MenuItem value={0}>Select State</MenuItem>
                  {totalStates}
                </Select>
              </FormControl>
            </Grid>
            <Grid md={1.5}>
              <FormControl fullWidth>
                <Select
                  value={selectedCity !== null ? selectedCity : ""}
                  size={"small"}
                  displayEmpty
                  inputProps={{ "aria-label": "City" }}
                  onChange={handleChangeCity}
                >
                  <MenuItem value={0}>Select city</MenuItem>
                  {totalCities}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={1.5}>
              <FormControl fullWidth>
                <Grid sx={EmaillableTwo}></Grid>
                <Select
                  value={orderStatus}
                  size={"small"}
                  displayEmpty
                  inputProps={{ "aria-label": "Order status" }}
                  onChange={handlestatusChange}
                >
                  <MenuItem value={null} disabled>
                    Order status
                  </MenuItem>
                  <MenuItem value={7}>Cancelled</MenuItem>
                  <MenuItem value={8}>Missed(AutoCancel)</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {loaderData ? (
        <Loader />
      ) : (
        <Grid sx={tableParentHolder}>
          <TableComponent headerData={TableHeaderRow} bodyData={TableBody} />
          <HFPagination details={paginationData} paginate={handlePaginate} />
        </Grid>
      )}
      <OrderModalComponent
        openhandler={isModalOpen}
        handleClose={handleCloseModal}
        orderId={orderId}
      />
      <SuccessAlert
        alertMsg={alrtMsg}
        open={successAlrtOpen}
        close={closeSuccess}
      />
      <ErrorAlert alertMsg={alrtMsg} open={errorAlrtOpen} close={closeError} />
    </>
  );
};

export default IncompleteOrders;
