import React from "react";
import {
  Grid,
  Modal,
  Box,
  Typography,
  Select,
  MenuItem,
  FormControl,
  IconButton,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { CommonServices } from "../services/common_services";
import { ManagementServices } from "../services/management_services";
import { FaqModalStyle, modalHeading, Errors } from "../styles/common_styles";
import { Emaillable, CustomField } from "../styles/loginstyles";
import { ButtonComponent } from "./form_components/button";
import CloseIcon from "@mui/icons-material/Close";
import { SuccessAlert } from "./alert";
import { ErrorAlert } from "./alert";

const AddCommercialModal = ({
  openhandler,
  handleClose,
  trigger,
  type,
  addType,
}) => {
  const {
    // register: register1,
    handleSubmit: handleSubmit1,
    // setValue: setValue1,
    // reset: reset1,
    formState: { errors: errors1 },
  } = useForm();

  const [selectedCountry, setSelectedCountry] = React.useState(null);
  const [selectedState, setSelectedState] = React.useState(null);
  const [selectedCity, setSelectedCity] = React.useState(null);
  const [countries, setCountries] = React.useState([]);
  const [states, setStates] = React.useState([]);
  const [cities, setCities] = React.useState([]);
  const [alrtMsg, setAlrtMsg] = React.useState("");
  const [successAlrtOpen, setSuccessAlrtOpen] = React.useState(false);
  const [errorAlrtOpen, setErrorAlrtOpen] = React.useState(false);
  // const [alrtMsg, setAlrtMsg] = React.useState("");
  // const [errorAlrtOpen, setErrorAlrtOpen] = React.useState(false);
  // const [successAlrtOpen, setSuccessAlrtOpen] = React.useState(false);

  const GetCountries = async () => {
    try {
      const payload = {
        type: 1,
      };
      const Countries = await CommonServices.getLocation(payload);
      if (Countries.status === 1) {
        setCountries(Countries.data);
      } else {
        alert(Countries.message);
      }
    } catch (error) {
      console.error(`Error occured while fetching roles:${error}`);
    }
  };

  const GetStates = async (type, country) => {
    try {
      const payload = {
        type: type,
        country: country,
      };
      const States = await CommonServices.getLocation(payload);
      if (States.status === 1) {
        setStates(States.data);
      } else {
        alert(States.message);
      }
    } catch (error) {
      console.error(`Error occured while fetching roles:${error}`);
    }
  };

  const GetCities = async (type, state, country) => {
    try {
      const payload = {
        type: type,
        country: country,
        state: state,
      };
      const Cities = await CommonServices.getLocation(payload);
      if (Cities.status === 1) {
        setCities(Cities.data);
      } else {
        alert(Cities.message);
      }
    } catch (error) {
      console.error(`Error occured while fetching cities:${error}`);
    }
  };

  const handleChangeCountry = (event) => {
    const { value } = event.target;
    setSelectedCountry(value);
    GetStates(2, event.target.value);
    setSelectedState(0);
  };

  const handleChangeState = (event) => {
    const { value } = event.target;
    setSelectedState(value);
    GetCities(2, event.target.value);
  };
  const handleChangeCity = (event) => {
    const { value } = event.target;
    setSelectedCity(value);
  };

  const closeSuccess = () => {
    setSuccessAlrtOpen(false);
  };

  const closeError = () => {
    setErrorAlrtOpen(false);
  };

  const totalCountries = countries.map((x) => (
    <MenuItem key={x.id} value={x.id}>
      {x.name}
    </MenuItem>
  ));

  const totalStates = states.map((x) => (
    <MenuItem key={x.id} value={x.id}>
      {x.name}
    </MenuItem>
  ));

  const totalCities = cities.map((x) => (
    <MenuItem key={x.id} value={x.id}>
      {x.name}
    </MenuItem>
  ));

  const commercialKitchenSubmit = async (data) => {
    const payload = {
      country_id: selectedCountry,
      state_id: selectedState,
      city_id: selectedCity,
      type: addType,
    };
    const insertion = await ManagementServices.addcommercialKitchenList(
      payload
    );
    if (insertion.status === 1) {
      handleClose();
      setAlrtMsg(insertion.message);
      setSuccessAlrtOpen(true);
      trigger();
      setSelectedCountry(null);
      setSelectedState(null);
      setSelectedCity(null);
    } else {
      setAlrtMsg(insertion.message);
      setErrorAlrtOpen(true);
    }
  };

  React.useEffect(() => {
    GetCountries();
  }, []);

  return (
    <>
      <Modal
        open={openhandler}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={FaqModalStyle}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <Typography sx={modalHeading}>
                {type ? "Update" : "Add"}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton
                onClick={handleClose}
                sx={{ color: "black", backgroundColor: "grey" }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Grid
            component={"form"}
            onSubmit={handleSubmit1(commercialKitchenSubmit)}
          >
            <Grid container justifyContent={"center"} spacing={3}>
              <Grid item md={10}>
                <FormControl fullWidth>
                  <Grid sx={Emaillable}>Country</Grid>
                  <Select
                    sx={CustomField}
                    value={selectedCountry || ""}
                    size={"small"}
                    onChange={handleChangeCountry}
                    displayEmpty
                    inputProps={{
                      "aria-label": "countryId",
                      sx: { "& input": { color: "#CCCCCC" } },
                    }}
                  >
                    <MenuItem value="" disabled>
                      Select country
                    </MenuItem>
                    {totalCountries}
                  </Select>
                  {errors1.countryId &&
                    errors1.countryId.type === "required" && (
                      <Typography sx={Errors}>
                        Please select country.
                      </Typography>
                    )}
                </FormControl>
              </Grid>
              <Grid item md={10}>
                <FormControl fullWidth>
                  <Grid sx={Emaillable}>State</Grid>
                  <Select
                    sx={CustomField}
                    value={selectedState || ""}
                    size={"small"}
                    onChange={handleChangeState}
                    displayEmpty
                    inputProps={{
                      "aria-label": "stateId",
                      sx: { "& input": { color: "#CCCCCC" } },
                    }}
                  >
                    <MenuItem value="" disabled>
                      Select State
                    </MenuItem>
                    {totalStates}
                  </Select>
                </FormControl>
              </Grid>
              {addType === 1 ||
                (3 && (
                  <Grid item md={10}>
                    <FormControl fullWidth>
                      <Grid sx={Emaillable}>City</Grid>
                      <Select
                        sx={CustomField}
                        value={selectedCity || ""}
                        size={"small"}
                        onChange={handleChangeCity}
                        displayEmpty
                        inputProps={{
                          "aria-label": "cityId",
                          sx: { "& input": { color: "#CCCCCC" } },
                        }}
                      >
                        <MenuItem value="" disabled>
                          Select City
                        </MenuItem>
                        {totalCities}
                      </Select>
                    </FormControl>
                  </Grid>
                ))}
              <Grid container justifyContent={"center"} p={3}>
                {type ? (
                  <ButtonComponent type={"submit"} btnText={"Update"} />
                ) : (
                  <ButtonComponent type={"submit"} btnText={"Add"} />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <SuccessAlert
        alertMsg={alrtMsg}
        open={successAlrtOpen}
        close={closeSuccess}
      />
      <ErrorAlert alertMsg={alrtMsg} open={errorAlrtOpen} close={closeError} />
    </>
  );
};

export default AddCommercialModal;
